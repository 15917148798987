import React, { useEffect, useState } from 'react';
import Navbar from "../components/Navbar";  // Assuming Navbar component
import Footer from "../components/Footer";

function Home() {
  const [noHeaderFooter, setNoHeaderFooter] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const noHeaderFooterParam = urlParams.get('noHeaderFooter');
    if (noHeaderFooterParam === 'true') {
      setNoHeaderFooter(true);
    }
  }, []);

  return (
    <div>
      {/* Conditionally render Navbar based on the noHeaderFooter flag */}
      {!noHeaderFooter && <Navbar />}
      
      <div className="home-content">
        <h1>Welcome to Home Page</h1>
        {/* Your homepage content goes here */}
      </div>

      {/* Conditionally render Footer based on the noHeaderFooter flag */}
      {!noHeaderFooter && <Footer />}
    </div>
  );
}

export default Home;
