import React from 'react';
import '../styling/preview.css';
import Footer from "../components/Footer";

function Reservation() {
  return (
    <div className="preview-container">
      <iframe 
        src="https://i2u.ai/reservation.html" 
        title="Reservation Content"
        className="preview-frame"
        style={{
          width: '100%',
          height: 'calc(100vh - 160px)', // Adjust based on your header/footer height
          border: 'none'
        }}
      />
      <Footer />
    </div>
  );
}

export default Reservation;
