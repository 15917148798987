import React from 'react';
import '../styling/FeaturesBenefits.scss';
import Footer from '../components/Footer';

const JobSeekers = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div style={{ flex: 1 }}>
        <div className="features-container">

          {/* Job Seekers Section */}
          <div className="feature-section">
            <h2 className="section-title">Job Seekers & Professionals</h2>
            <div className="feature-content">
              <h3 style={{ textAlign: 'center' }}>Leverage AI at i2u.ai to Help Startups Become Unicorns!</h3>
              <p>Elevate your career journey with i2u.ai, where innovation meets professional growth. Our platform is designed to empower job seekers and professionals with AI-driven insights and opportunities that open new pathways in their careers. From job searching to skills enhancement, i2u.ai is your partner for success.</p>

              <h3>Core Features</h3>
              
              <h4>AI-Driven Job Matching</h4>
              <p>Finding the right job can be daunting. Our AI-driven job matching technology identifies positions that align with your skills, interests, and career goals, streamlining your job search and connecting you with the roles that best suit your aspirations.</p>

              <h4>Upskilling and Certification</h4>
              <p>Stay competitive in the job market with our comprehensive upskilling and certification programs. We offer courses across various domains, ensuring you have the competencies required to excel in your chosen field.</p>

              <h4>Market Insights and Career Advice</h4>
              <p>Navigating career transitions requires informed decision-making. At i2u.ai, we provide valuable market insights and personalized career advice, helping you make informed choices that align with your professional growth.</p>

              <h3>Key Advantages</h3>

              <h4>Precision Job Matching</h4>
              <p>By leveraging AI, we match you with job opportunities that align with your skills and career objectives, reducing the time and effort spent on traditional job searches.</p>

              <h4>Competitive Edge through Skills Enhancement</h4>
              <p>Continuous learning ensures you remain relevant and competitive in your field. Our upskilling programs offer the latest in industry knowledge and practices, equipping you with a distinct advantage.</p>

              <h4>Professional Development Resources</h4>
              <p>Access a wealth of resources aimed at your professional development. From resume building workshops to career counseling, i2u.ai offers tools and support to ensure your career progression.</p>

              <h3>Benefits for Subscribers</h3>

              <h4>Explore New Opportunities</h4>
              <p>Gain access to a curated list of job openings, industry trends, and market forecasts, positioning you to seize opportunities that others might overlook.</p>

              <h4>Build a Resilient Career Foundation</h4>
              <p>Enhance your AI and industry knowledge through workshops and training sessions, securing a strong foundation for long-term career success.</p>

              <h4>Expand Your Professional Network</h4>
              <p>Join a vibrant community of entrepreneurs, professionals, and job seekers. Our platform promotes collaboration and networking, expanding your reach and influence in your industry.</p>

              <h4>Achieve Career Goals Faster</h4>
              <p>Accelerate your career progression with targeted learning paths and strategic career advice, ensuring you achieve your professional goals efficiently.</p>

              <h4>Increase Employability and Visibility</h4>
              <p>Maximize your chances of standing out to employers with i2u.ai's tools and features designed to enhance your profile and increase your visibility in the job market.</p>

              <h3>Join Us at i2u.ai</h3>
              <p>Step into a world of opportunities with i2u.ai, where innovation fuels your career. Whether you're searching for a new job, aiming to upskill, or seeking mentorship, our platform is the key to accelerating your career growth. Join our dynamic community today and experience the transformative power of AI in your professional life!</p>
            </div>
          </div>

          {/* Employees Section */}
          <div className="feature-section">
            <h2 className="section-title">Employees</h2>
            <div className="feature-content">
              <h3 style={{ textAlign: 'center' }}>Amplify Your Career Prospects with i2u.ai</h3>
              <p>Our platform is dedicated to empowering startup employees, providing you with the AI tools and resources necessary to excel in a rapidly evolving industry. Whether you're aiming to upskill or make impactful contributions to your startup, i2u.ai supports your professional journey every step of the way.</p>

              <h3>Core Features</h3>
              
              <h4>Skill Development and Training</h4>
              <p>Keeping up with technological advancements is essential in today's job market. At i2u.ai, we offer targeted training programs that enhance your expertise in AI and other pivotal sectors. Our expertly curated courses cover everything from foundational concepts to advanced applications, ensuring you're always equipped to meet new challenges.</p>

              <h4>Career Growth Support</h4>
              <p>Navigating your career path can be challenging in the fast-paced startup world. Our platform provides guidance on career progression, helping you set goals and achieve milestones. With personalized mentorship and career counseling, we support your aspirations and help forge a path to success.</p>

              <h4>Industry Insights and Networking</h4>
              <p>Understanding industry trends and connecting with key stakeholders can significantly boost your career. i2u.ai grants you access to a wealth of industry insights and a thriving network of professionals, opening doors to new opportunities and collaborations.</p>

              <h3>Key Advantages</h3>

              <h4>Enhanced Skill Set</h4>
              <p>Developing a robust skill set is crucial for career advancement. Our platform offers continuous learning opportunities, ensuring you stay ahead of the curve with the latest industry knowledge and AI capabilities.</p>

              <h4>Increased Visibility & Recognition</h4>
              <p>Stand out in your startup and beyond by showcasing your skills and achievements. i2u.ai provides platforms and opportunities to highlight your contributions, increasing your visibility among peers and potential employers.</p>

              <h4>Professional Networking</h4>
              <p>Forge meaningful connections with fellow professionals, industry leaders, and potential employers. Our networking events and community engagements offer the perfect platform to build relationships that can enhance your career trajectory.</p>

              <h3>Join i2u.ai Today!</h3>
              <p>Step into a world of opportunities with i2u.ai, where your career potential meets unlimited possibilities. Whether you're aiming for career advancement, seeking industry insights, or wanting to upskill, i2u.ai is your partner in success. Join our dynamic community today and experience the transformative power of AI in your professional life!</p>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JobSeekers;
